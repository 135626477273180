/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { InboxOutlined } from '@ant-design/icons';
import { Button, Col, message, Upload } from 'antd';
import Text from 'antd/lib/typography/Text';
import saveAs from 'file-saver';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { exportTransportData, getTransportInfoData, uploadTransportData } from '../../services/api-service';
import { showError } from '../../utils/message';
import { debouncedSave } from '../../utils/utils';
import TransportDataList from './transport-data-list';
import { useSelector } from 'react-redux';

const { Dragger } = Upload;

export default function UploadTransportType() {
  const [csvData, setCsvData] = useState([]);
  const uploadFiles = useRef([]);
  const [loading, setLoading] = useState(false);
  const [resetCurrentPage, setResetCurrentPage] = useState(false);
  const maxFile = 1;
  const { user } = useSelector((state) => {
    return {
      user: state.auth.user,
    };
  });
  const isKokuyoAccount = user?.email?.includes('@kokuyo.com');

  const handleGetData = () => {
    setLoading(true);
    setCsvData([]);
    getTransportInfoData()
      .then((response) => {
        showData(response);
      })
      .catch((error) => {
        showError('エラー', error?.response?.data?.recordLimit || '管理会社にお問い合わせください。');
      })
      .finally(() => {
        setLoading(false);
        setResetCurrentPage(true);
        uploadFiles.current = [];
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceApi = useCallback(debouncedSave(handleGetData, 1000), []);

  const isExist = (file) => {
    return uploadFiles.current.filter((item) => item.uid === file.uid).length > 0;
  };

  const dumpRequest = async ({ file, onSuccess, onError }) => {
    if (isExist(file)) {
      const response = uploadTransportData(file);
      response
        .then((result) => onSuccess('ok', result))
        .catch((error) => {
          showError('エラー', error?.response?.data?.error);
          onError(`File upload failed.`);
        });
    } else {
      onSuccess('ok');
    }
  };

  const props = {
    name: 'file',
    multiple: false,
    accept: '.csv,.xlsx',
    maxCount: maxFile,
    onChange(info) {
      const { status } = info.file;
      const { fileList } = info;
      uploadFiles.current = fileList;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        console.log('call api');
        debounceApi();
      }
    },
    customRequest: dumpRequest,
    showUploadList: false,
  };

  moment.locale('ja');

  useEffect(() => {
    setLoading(true);
    getTransportInfoData()
      .then((response) => {
        showData(response);
      })
      .catch((error) => {
        showError('エラー', error?.response?.data?.recordLimit || '管理会社にお問い合わせください。');
      })
      .finally(() => {
        setLoading(false);
        setResetCurrentPage(true);
      });
  }, []);

  const showData = (response) => {
    if (!response) return;
    if (Array.isArray(response)) {
      const temp = response.map((item) => {
        return {
          業者名: item.companyName,
          業者ID: item.companyCode,
          設定１: item.setting1,
          設定２: item.setting2,
          設定３: item.setting3,
          設定４: item.setting4,
          設定５: item.setting5,
          設定６: item.setting6,
          設定７: item.setting7,
          設定８: item.setting8,
          設定９: item.setting9,
          設定１０: item.setting10,
        };
      });
      setCsvData(temp);
    }
  };

  const handleExportExcel = () => {
    setLoading(true);
    exportTransportData()
      .then((response) => {
        const contentDisposition = response.headers['content-disposition'];
        const filename = decodeURIComponent(contentDisposition.split("''")[1].replace(/"/g, '')); // Extract the filename from the header

        saveAs(new Blob([response.data]), filename);
      })
      .catch((error) => {
        showError('エラー', error?.response?.data?.error || '管理会社にお問い合わせください。');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Col
      style={{
        height: '100%',
      }}
    >
      <>
        <Text>
          アップロードファイルの雛形は
          <a
            href={
              isKokuyoAccount
                ? '/static/追跡業者＿紐付テンプレートファイル（コクヨ用）.xlsx'
                : '/static/追跡業者＿紐付テンプレートファイル.xlsx'
            }
          >
            こちら
          </a>
          をご確認ください。
        </Text>
        <br />
        <br />
        <Dragger
          {...props}
          style={{
            marginBottom: '2em',
          }}
        >
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>
            業者コードと任意の運送業者名の紐付けデータアップロードエリア
            <br />
            この領域にクリックしてファイルを選択するか、又はファイルをドラッグしてアップロードします。
          </p>
        </Dragger>
      </>

      {csvData.length > 0 && (
        <Button
          type='primary'
          size='large'
          style={{
            marginRight: '20px',
            marginTop: '2em',
          }}
          onClick={handleExportExcel}
        >
          EXCEL出力
        </Button>
      )}

      <TransportDataList csvData={csvData} reset={resetCurrentPage} loading={loading} />
    </Col>
  );
}
