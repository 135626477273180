import { DeleteOutlined, InboxOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Select, Upload, message, Progress, Radio } from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { useScale } from '../../hooks/scale';
import {
  cancelJob,
  getDeliveryInfo,
  uploadFile,
  uploadFileLimit,
  uploadLinkedData,
  getTransportCategories,
} from '../../services/api-service';
import { camelCase } from '../../utils/convertKeys';
import { showError } from '../../utils/message';
import { hasPermission, numberWithCommas } from '../../utils/utils';
import NumericInput from '../controls/input-number';
import SelectColumnExportModal from '../modals/select-column-export-modal';
import DeliverInfoList from './deliver-info-list';
import DeliverInfoListMobile from './deliver-info-list-mobile';
import { isEmpty } from 'lodash';

const { Dragger } = Upload;
const { Option } = Select;

const TRANSPORTS = [
  {
    value: 'MT',
    label: '名鉄運輸',
  },
  {
    value: 'DK',
    label: '第一貨物',
  },
  {
    value: 'YM',
    label: 'ヤマト運輸',
  },
  {
    value: 'SG',
    label: '佐川急便',
  },
  {
    value: 'SN',
    label: '西濃運輸',
  },
  {
    value: 'FT',
    label: '福山通運',
  },
  {
    value: 'TN',
    label: 'トナミ運輸',
  },
  {
    value: 'CB',
    label: '千葉通商',
  },
  {
    value: 'SB',
    label: 'SBS即配',
  },
  {
    value: 'YB',
    label: '日本郵便',
  },
  {
    value: 'SL',
    label: 'エスラインギフ',
  },
  {
    value: 'OK',
    label: '岡山県貨物',
  },
  {
    value: 'KM',
    label: '久留米運送',
  },
  {
    value: 'MM',
    label: '松岡満運輸',
  },
  {
    value: 'SK',
    label: '四国運輸',
  },
  {
    value: 'OW',
    label: '沖縄物流',
  },
  {
    value: 'RF',
    label: 'ラニイ福井貨物',
  },
  {
    value: 'LN',
    label: 'ロジネットジャパン西日本',
  },
  {
    value: 'KR',
    label: '近物レックス',
  },
  {
    value: 'NG',
    label: '新潟運輸',
  },
  {
    value: 'MB',
    label: 'メタル便',
  },
  {
    value: 'TL',
    label: 'JPロジスティクス',
  },
  {
    value: 'NT',
    label: '日本通運',
  },
  {
    value: 'OH',
    label: 'ハンナ',
  },
  {
    value: 'KT',
    label: '北通',
  },
  {
    value: 'JW',
    label: 'ジャパンウエスト',
  },
  {
    value: 'KE',
    label: '近藤急便',
  },
  {
    value: 'KW',
    label: 'ワーク',
  },
  {
    value: 'CF',
    label: 'キャリーフラップ・カーゴスタッフ',
  },
  {
    value: 'SP',
    label: '札幌通運',
  },
  {
    value: 'KD',
    label: '関東即配',
  },
  {
    value: 'CT',
    label: '中越運送',
  },
  {
    value: 'HT',
    label: '飛騨運輸',
  },
  {
    value: 'KS',
    label: '加勢',
  },
  {
    value: 'KL',
    label: 'アスクルロジスト',
  },
  {
    value: 'SS',
    label: 'セイノースーパー',
  },
  {
    value: 'LQ',
    label: 'ロジクエスト',
  },
];

const CD_ID = {
  CB: {
    cd: '42173',
    id: '048F9',
  },
  OH: {
    cd: '00103',
    id: '19207',
  },
  KT: {
    cd: '00103',
    id: '19207',
  },
  JW: {
    cd: '00103',
    id: '19207',
  },
  KE: {
    cd: '00103',
    id: '19207',
  },
  KW: {
    cd: '00103',
    id: '19207',
  },
  CF: {
    cd: '16119',
    id: '0E2E5',
  },
};

export default function UploadFile() {
  const { user } = useSelector((state) => {
    return {
      user: state.auth.user,
    };
  });
  const isShowInputColumn = hasPermission(user, 1);
  const isShowExportCSVWithSpecifyColumn = hasPermission(user, 3);
  const [csvData, setCsvData] = useState([]);
  const [originalCsvData, setOriginalCsvData] = useState([]);
  const [outputCsvSelectedColumn, setOutputCsvSelectedColumn] = useState([]);
  const [progressInfo, setProgressInfo] = useState({ current: 0, total: 0 });
  const [transportCategories, setTransportCategories] = useState(TRANSPORTS);
  const uploadFiles = useRef([]);
  const ids = useRef([]);
  const fileIds = useRef({});
  const [loading, setLoading] = useState(false);
  const [isCanceling, setCanceling] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState([]);
  const [transport, setTransport] = useState('MT');
  const [freeIds, setFreeIds] = useState('');
  const [status, setStatus] = useState('');
  const [trackingNumberColumn, setTrackingNumberColumn] = useState('');
  const [transportColumn, setTransportColumn] = useState('');
  const [companyCodeColumn, setCompanyCodeColumn] = useState('');
  const [resetCurrentPage, setResetCurrentPage] = useState(false);
  const [haveHeader, setHaveHeader] = useState({});
  const { pathname } = useLocation();
  const isFree = pathname === '/delivery-list-trial';
  const isDedicatedSearch = pathname === '/dedicated-search';
  const maxFile = isFree ? 1 : 5;
  const isMobile = useScale();
  const isKokuyoAccount = user?.email?.includes('@kokuyo.com');
  const [csvName, setCsvName] = useState('');

  const tid = useRef('');

  const isExist = (file) => {
    return uploadFiles.current.filter((item) => item.uid === file.uid).length > 0;
  };

  useEffect(() => {
    getTransportCategories().then((response) => {
      if (!isEmpty(response)) {
        const transports = response.map((item) => {
          return {
            value: item.companyCode,
            label: item.companyName,
          };
        });
        setTransportCategories(transports);
      }
    });
  }, []);

  const dumpRequest = async ({ file, onSuccess, onError }) => {
    if (isExist(file)) {
      const response = !isFree
        ? isDedicatedSearch
          ? uploadLinkedData(file)
          : uploadFile(file)
        : uploadFileLimit(file);
      response
        .then((result) => onSuccess('ok', result))
        .catch((error) => {
          if (error.response?.data?.file[0]) {
            message.error(error.response?.data?.file[0]);
          } else {
            message.error(`File upload failed.`);
          }
          onError(`File upload failed.`);
        });
    } else {
      onSuccess('ok');
    }
  };

  const props = {
    name: 'file',
    multiple: true,
    accept: '.csv,.xls,.xlsx',
    maxCount: maxFile,
    onChange(info) {
      const { status } = info.file;
      const { fileList, file } = info;
      uploadFiles.current = fileList;
      if (status === 'done') {
        fileIds.current = { ...fileIds.current, [file.uid]: file.xhr.id };
        ids.current = [...ids.current, file.xhr.id].slice(-1 * maxFile);
        setTrackingNumber(ids.current);
        setHaveHeader({ ...haveHeader, [file.xhr.id]: false });
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'removed') {
        ids.current = ids.current.filter((id) => id !== fileIds.current[file.uid]);
        const newHaveHeader = { ...haveHeader };
        delete newHaveHeader[fileIds.current[file.uid]];
        setHaveHeader(newHaveHeader);
        setTrackingNumber(ids.current);
      }
    },
    customRequest: dumpRequest,
  };

  moment.locale('ja');

  const getName = (type) => {
    const transport = transportCategories.filter((x) => x.value === type);

    return transport[0] ? transport[0].label : '';
  };

  const generateData = (response, matchingData, requestTime, readySearch, type) => {
    const deliveryInfoKw = [];
    const responseDataKw = response.data[type.toLowerCase()];
    const deliverNumbersKw = response.deliverNumbers[type.toLowerCase()];
    const transportName = getName(type);
    for (let index = 0; index < responseDataKw.length; index = index + 5) {
      deliveryInfoKw.push({
        業者コード: type,
        業者名: transportName,
        問合番号: matchingData[responseDataKw[index]] || responseDataKw[index],
        問合結果: responseDataKw[index + 1],
        問合時間: requestTime,
        結果時間: responseDataKw[index + 2],
        最終配達ターミナル名: responseDataKw[index + 3],
        問合せ電話番号: responseDataKw[index + 4],
        各社サイト: `https://y-track.jp/ytrack/inqservlet?cd=${CD_ID[type].cd}&id=${CD_ID[type].id}&number01=${
          matchingData[responseDataKw[index]] || responseDataKw[index]
        }`,
      });
    }
    const combineDataKw = deliverNumbersKw.map((item) => {
      const deliveryItem = deliveryInfoKw.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: type, ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoKw.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: type };
        } else {
          return {
            業者コード: type,
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://y-track.jp/ytrack/inqservlet?cd=${CD_ID[type].cd}&id=${CD_ID[type].id}&number01=${item}`,
          };
        }
      }
    });
    return combineDataKw;
  };

  const generateDataCarryFlap = (response, matchingData, requestTime, readySearch, type) => {
    const deliveryInfoKw = [];
    const responseDataKw = response.data[type.toLowerCase()];
    const deliverNumbersKw = response.deliverNumbers[type.toLowerCase()];
    const transportName = getName(type);
    for (let index = 0; index < responseDataKw.length; index = index + 5) {
      deliveryInfoKw.push({
        業者コード: type,
        業者名: transportName,
        問合番号: matchingData[responseDataKw[index]] || responseDataKw[index],
        問合結果: responseDataKw[index + 1],
        問合時間: requestTime,
        結果時間: responseDataKw[index + 2],
        最終配達ターミナル名: responseDataKw[index + 3],
        問合せ電話番号: responseDataKw[index + 4],
        各社サイト: `https://tracking.carry-flap.com/#/home`,
      });
    }
    const combineDataKw = deliverNumbersKw.map((item) => {
      const deliveryItem = deliveryInfoKw.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: type, ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoKw.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: type };
        } else {
          return {
            業者コード: type,
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://tracking.carry-flap.com/#/home`,
          };
        }
      }
    });
    return combineDataKw;
  };

  const generateDataSattsu = (response, matchingData, requestTime, readySearch, type) => {
    const deliveryInfoLn = [];
    const responseDataLn = response.data[type.toLowerCase()];
    const deliverNumbersLn = response.deliverNumbers[type.toLowerCase()];
    const transportName = getName(type);
    for (let index = 0; index < responseDataLn.length; index = index + 5) {
      deliveryInfoLn.push({
        業者コード: type,
        業者名: transportName,
        問合番号: matchingData[responseDataLn[index]] || responseDataLn[index],
        問合結果: responseDataLn[index + 1],
        問合時間: requestTime,
        結果時間: responseDataLn[index + 2],
        最終配達ターミナル名: responseDataLn[index + 3],
        問合せ電話番号: responseDataLn[index + 4],
        各社サイト: 'https://www.sattsu.co.jp/kamotsu/ewebLogin.aspx',
      });
    }
    const combineDataLn = deliverNumbersLn.map((item) => {
      const deliveryItem = deliveryInfoLn.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: type, ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoLn.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: type };
        } else {
          return {
            業者コード: type,
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: 'https://www.sattsu.co.jp/kamotsu/ewebLogin.aspx',
          };
        }
      }
    });
    return combineDataLn;
  };

  const generateDataForSBS = (response, matchingData, requestTime, readySearch, type) => {
    const deliveryInfoSb = [];
    const responseDataSb = response.data[type.toLowerCase()];
    const deliverNumbersSb = response.deliverNumbers[type.toLowerCase()];
    const transportName = getName(type);
    for (let index = 0; index < responseDataSb.length; index = index + 5) {
      deliveryInfoSb.push({
        業者コード: type,
        業者名: transportName,
        問合番号: matchingData[responseDataSb[index]] || responseDataSb[index],
        問合結果: responseDataSb[index + 1],
        問合時間: requestTime,
        結果時間: responseDataSb[index + 2],
        最終配達ターミナル名: responseDataSb[index + 3],
        問合せ電話番号: responseDataSb[index + 4],
        各社サイト: `https://www.saqura-web.com/sbs_ltrc/KF_5020.aspx?IraNo=${
          matchingData[responseDataSb[index]] || responseDataSb[index]
        }`,
      });
    }
    const combineDataSb = deliverNumbersSb.map((item) => {
      const deliveryItem = deliveryInfoSb.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: type, ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoSb.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: type };
        } else {
          return {
            業者コード: type,
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://www.saqura-web.com/sbs_ltrc/KF_5020.aspx?IraNo=${item}`,
          };
        }
      }
    });
    return combineDataSb;
  };

  const generateDataJapanLogistic = (response, matchingData, requestTime, readySearch, type) => {
    const deliveryInfoTl = [];
    const responseDataTl = response.data[type.toLowerCase()];
    const deliverNumbersTl = response.deliverNumbers[type.toLowerCase()];
    const transportName = getName(type);
    for (let index = 0; index < responseDataTl.length; index = index + 5) {
      deliveryInfoTl.push({
        業者コード: type,
        業者名: transportName,
        問合番号: matchingData[responseDataTl[index]] || responseDataTl[index],
        問合結果: responseDataTl[index + 1],
        問合時間: requestTime,
        結果時間: responseDataTl[index + 2],
        最終配達ターミナル名: responseDataTl[index + 3],
        問合せ電話番号: responseDataTl[index + 4],
        各社サイト: 'https://www.jp-logistics.jp/fwexphp/inquiry/chase/init',
      });
    }
    const combineDataTl = deliverNumbersTl.map((item) => {
      const deliveryItem = deliveryInfoTl.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: type, ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoTl.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: type };
        } else {
          return {
            業者コード: type,
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: 'https://www.jp-logistics.jp/fwexphp/inquiry/chase/init',
          };
        }
      }
    });
    return combineDataTl;
  };

  const showData = (response, requestTime) => {
    response = response.data;
    if (!response) return;
    const mapCustomerCode = response.data['customerCode'];
    const sortTypes = response.data['sortTypes'] || [];
    const deliveryInfo = [];
    const responseData = response.data['mt'];
    const deliverNumbers = response.deliverNumbers['mt'];
    const matchingData = response.data['matching'] || {};
    const readySearch = response.data['readySearch'] || {};

    let transportName = getName('MT');
    for (let index = 0; index < responseData.length; index = index + 4) {
      const temp = responseData[index + 3].split(' ');
      deliveryInfo.push({
        業者コード: 'MT',
        業者名: transportName,
        問合番号: matchingData[responseData[index + 1]] || responseData[index + 1],
        問合結果: responseData[index],
        問合時間: requestTime,
        結果時間: responseData[index + 2],
        最終配達ターミナル名: responseData[index + 3].split(' ')[0],
        問合せ電話番号: temp[1] + (temp.length > 2 ? temp[2] : ''),
        各社サイト: 'https://ap.meitetsuunyu.co.jp/webtrace/webtsuiseki/webtsuiseki.aspx',
      });
    }
    const combineData = deliverNumbers.map((item) => {
      const deliveryItem = deliveryInfo.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'MT', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfo.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'MT' };
        } else {
          return {
            業者コード: 'MT',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: 'https://ap.meitetsuunyu.co.jp/webtrace/webtsuiseki/webtsuiseki.aspx',
          };
        }
      }
    });

    // Process data for 第一貨物
    const deliveryInfoDk = [];
    const responseDataDk = response.data['dk'];
    const deliverNumbersDk = response.deliverNumbers['dk'];
    transportName = getName('DK');
    for (let index = 0; index < responseDataDk.length; index = index + 5) {
      deliveryInfoDk.push({
        業者コード: 'DK',
        業者名: transportName,
        問合番号: matchingData[responseDataDk[index + 1]] || responseDataDk[index + 1],
        問合結果: responseDataDk[index],
        問合時間: requestTime,
        結果時間: responseDataDk[index + 3],
        最終配達ターミナル名: responseDataDk[index + 2].split('　')[0],
        問合せ電話番号: responseDataDk[index + 2].split('　')[1],
        各社サイト: responseDataDk[index + 4],
      });
    }
    const combineDataDk = deliverNumbersDk.map((item) => {
      const deliveryItem = deliveryInfoDk.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'DK', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoDk.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'DK' };
        } else {
          return {
            業者コード: 'DK',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: mapCustomerCode[item]
              ? `https://www.daiichi-kamotsu.co.jp/cgi/W3X05IM.php?NCOD=${mapCustomerCode[item]}&KEYA=${item}`
              : `https://www.daiichi-kamotsu.co.jp/cgi/W3X05IM.php?KEY1=${item}`,
          };
        }
      }
    });

    // Process data for ヤマト運輸
    const deliveryInfoYm = [];
    const responseDataYm = response.data['ym'];
    const deliverNumbersYm = response.deliverNumbers['ym'];
    transportName = getName('YM');
    for (let index = 0; index < responseDataYm.length; index = index + 5) {
      deliveryInfoYm.push({
        業者コード: 'YM',
        業者名: transportName,
        問合番号: matchingData[responseDataYm[index]] || responseDataYm[index],
        問合結果: responseDataYm[index + 1],
        問合時間: requestTime,
        結果時間: responseDataYm[index + 2],
        最終配達ターミナル名: responseDataYm[index + 3],
        問合せ電話番号: responseDataYm[index + 4],
        各社サイト: `http://jizen.kuronekoyamato.co.jp/jizen/servlet/crjz.b.NQ0010?id=${
          matchingData[responseDataYm[index]] || responseDataYm[index]
        }`,
      });
    }

    const combineDataYm = deliverNumbersYm.map((item) => {
      const deliveryItem = deliveryInfoYm.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'YM', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoYm.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'YM' };
        } else {
          return {
            業者コード: 'YM',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `http://jizen.kuronekoyamato.co.jp/jizen/servlet/crjz.b.NQ0010?id=${item}`,
          };
        }
      }
    });

    // Process data for 佐川急便
    const deliveryInfoSg = [];
    const responseDataSg = response.data['sg'];
    const deliverNumbersSg = response.deliverNumbers['sg'];
    transportName = getName('SG');
    for (let index = 0; index < responseDataSg.length; index = index + 5) {
      deliveryInfoSg.push({
        業者コード: 'SG',
        業者名: transportName,
        問合番号: matchingData[responseDataSg[index]] || responseDataSg[index],
        問合結果: responseDataSg[index + 1],
        問合時間: requestTime,
        結果時間: responseDataSg[index + 3],
        最終配達ターミナル名: responseDataSg[index + 2],
        問合せ電話番号: responseDataSg[index + 4],
        各社サイト: `http://k2k.sagawa-exp.co.jp/p/web/okurijosearch.do?okurijoNo=${
          matchingData[responseDataSg[index]] || responseDataSg[index]
        }`,
      });
    }
    const combineDataSg = deliverNumbersSg.map((item) => {
      const deliveryItem = deliveryInfoSg.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'SG', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoSg.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'SG' };
        } else {
          return {
            業者コード: 'SG',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `http://k2k.sagawa-exp.co.jp/p/web/okurijosearch.do?okurijoNo=${item}`,
          };
        }
      }
    });

    // Process data for 西濃運輸
    const deliveryInfoSn = [];
    const responseDataSn = response.data['sn'];
    const deliverNumbersSn = response.deliverNumbers['sn'];
    transportName = getName('SN');
    for (let index = 0; index < responseDataSn.length; index = index + 5) {
      deliveryInfoSn.push({
        業者コード: 'SN',
        業者名: transportName,
        問合番号: matchingData[responseDataSn[index]] || responseDataSn[index],
        問合結果: responseDataSn[index + 1],
        問合時間: requestTime,
        結果時間: responseDataSn[index + 2],
        最終配達ターミナル名: responseDataSn[index + 3],
        問合せ電話番号: responseDataSn[index + 4],
        各社サイト: `https://track.seino.co.jp/kamotsu/GempyoNoShokai.do?GNPNO1=${
          matchingData[responseDataSn[index]] || responseDataSn[index]
        }&inputGNPNO1=${
          matchingData[responseDataSn[index]] || responseDataSn[index]
        }&action=%81%40%8C%9F+%8D%F5%81%40#shousai0`,
      });
    }
    const combineDataSn = deliverNumbersSn.map((item) => {
      const deliveryItem = deliveryInfoSn.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'SN', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoSn.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'SN' };
        } else {
          return {
            業者コード: 'SN',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://track.seino.co.jp/kamotsu/GempyoNoShokai.do?GNPNO1=${item}&inputGNPNO1=${item}&action=%81%40%8C%9F+%8D%F5%81%40#shousai0`,
          };
        }
      }
    });

    // Process data for 福山通運
    const deliveryInfoFt = [];
    const responseDataFt = response.data['ft'];
    const deliverNumbersFt = response.deliverNumbers['ft'];
    transportName = getName('FT');
    for (let index = 0; index < responseDataFt.length; index = index + 5) {
      deliveryInfoFt.push({
        業者コード: 'FT',
        業者名: transportName,
        問合番号: matchingData[responseDataFt[index]] || responseDataFt[index],
        問合結果: responseDataFt[index + 1],
        問合時間: requestTime,
        結果時間: responseDataFt[index + 2],
        最終配達ターミナル名: responseDataFt[index + 3],
        問合せ電話番号: responseDataFt[index + 4],
        各社サイト: `https://corp.fukutsu.co.jp/situation/tracking_no_hunt/${
          matchingData[responseDataFt[index]] || responseDataFt[index]
        }`,
      });
    }
    const combineDataFt = deliverNumbersFt.map((item) => {
      const deliveryItem = deliveryInfoFt.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'FT', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoFt.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'FT' };
        } else {
          return {
            業者コード: 'FT',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://corp.fukutsu.co.jp/situation/tracking_no_hunt/${item}`,
          };
        }
      }
    });
    // Process data for トナミ運輸
    const deliveryInfoTn = [];
    const responseDataTn = response.data['tn'];
    const deliverNumbersTn = response.deliverNumbers['tn'];
    transportName = getName('TN');
    for (let index = 0; index < responseDataTn.length; index = index + 5) {
      deliveryInfoTn.push({
        業者コード: 'TN',
        業者名: transportName,
        問合番号: matchingData[responseDataTn[index]] || responseDataTn[index],
        問合結果: responseDataTn[index + 1],
        問合時間: requestTime,
        結果時間: responseDataTn[index + 2],
        最終配達ターミナル名: responseDataTn[index + 3],
        問合せ電話番号: responseDataTn[index + 4],
        各社サイト: `https://trc1.tonami.co.jp/trc/search3/excSearch3?id[0]=${responseDataTn[index]}`,
      });
    }
    const combineDataTn = deliverNumbersTn.map((item) => {
      const deliveryItem = deliveryInfoTn.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'TN', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoTn.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'TN' };
        } else {
          return {
            業者コード: 'TN',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://trc1.tonami.co.jp/trc/search3/excSearch3?id[0]=${item}`,
          };
        }
      }
    });

    // Process data for 千葉通商
    const combineDataCb = generateData(response, matchingData, requestTime, readySearch, 'CB');

    // Process data for ハンナ
    const combineDataOh = generateData(response, matchingData, requestTime, readySearch, 'OH');

    // Process data for 北通
    const combineDataKt = generateData(response, matchingData, requestTime, readySearch, 'KT');

    // Process data for ジャパンウエスト
    const combineDataJw = generateData(response, matchingData, requestTime, readySearch, 'JW');

    // Process data for 近藤急便
    const combineDataKe = generateData(response, matchingData, requestTime, readySearch, 'KE');

    // Process data for ワーク
    const combineDataKw = generateData(response, matchingData, requestTime, readySearch, 'KW');

    // Process data for キャリーフラップ・カーゴスタッフ
    const combineDataCf = generateDataCarryFlap(response, matchingData, requestTime, readySearch, 'CF');

    // Process data for SBS即配
    const combineDataSb = generateDataForSBS(response, matchingData, requestTime, readySearch, 'SB');

    // Process data for 関東即配
    const combineDataKd = generateDataForSBS(response, matchingData, requestTime, readySearch, 'KD');

    // Process data for 日本郵便
    const deliveryInfoYb = [];
    const responseDataYb = response.data['yb'];
    const deliverNumbersYb = response.deliverNumbers['yb'];
    transportName = getName('YB');
    for (let index = 0; index < responseDataYb.length; index = index + 5) {
      deliveryInfoYb.push({
        業者コード: 'YB',
        業者名: transportName,
        問合番号: matchingData[responseDataYb[index]] || responseDataYb[index],
        問合結果: responseDataYb[index + 1],
        問合時間: requestTime,
        結果時間: responseDataYb[index + 2],
        最終配達ターミナル名: responseDataYb[index + 3],
        問合せ電話番号: responseDataYb[index + 4],
        各社サイト: `https://trackings.post.japanpost.jp/services/srv/search/direct?org.apache.struts.taglib.html.TOKEN=&searchKind=S002&locale=ja&SVID=&reqCodeNo1=${
          matchingData[responseDataYb[index]] || responseDataYb[index]
        }`,
      });
    }
    const combineDataYb = deliverNumbersYb.map((item) => {
      const deliveryItem = deliveryInfoYb.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'YB', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoYb.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'YB' };
        } else {
          return {
            業者コード: 'YB',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://trackings.post.japanpost.jp/services/srv/search/direct?org.apache.struts.taglib.html.TOKEN=&searchKind=S002&locale=ja&SVID=&reqCodeNo1=${item}`,
          };
        }
      }
    });

    // Process data for エスラインギフ
    const deliveryInfoSl = [];
    const responseDataSl = response.data['sl'];
    const deliverNumbersSl = response.deliverNumbers['sl'];
    transportName = getName('SL');
    for (let index = 0; index < responseDataSl.length; index = index + 5) {
      deliveryInfoSl.push({
        業者コード: 'SL',
        業者名: transportName,
        問合番号: matchingData[responseDataSl[index]] || responseDataSl[index],
        問合結果: responseDataSl[index + 1],
        問合時間: requestTime,
        結果時間: responseDataSl[index + 2],
        最終配達ターミナル名: responseDataSl[index + 3],
        問合せ電話番号: responseDataSl[index + 4],
        各社サイト: `https://www.sline.co.jp/tuiseki/SLWJ0100?CusNo_1=000000&CusNo_2=000000&GenNo_1=${
          matchingData[responseDataSl[index]] || responseDataSl[index]
        }&GenNo_3=&GenNo_4=&GenNo_5=&sousin=%8F%C6%81%40%89%EF&DispKbn=10`,
      });
    }
    const combineDataSl = deliverNumbersSl.map((item) => {
      const deliveryItem = deliveryInfoSl.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'SL', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoSl.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'SL' };
        } else {
          return {
            業者コード: 'SL',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://www.sline.co.jp/tuiseki/SLWJ0100?CusNo_1=000000&CusNo_2=000000&GenNo_1=${item}&GenNo_3=&GenNo_4=&GenNo_5=&sousin=%8F%C6%81%40%89%EF&DispKbn=10`,
          };
        }
      }
    });

    // Process data for 岡山県貨物
    const deliveryInfoOk = [];
    const responseDataOk = response.data['ok'];
    const deliverNumbersOk = response.deliverNumbers['ok'];
    transportName = getName('OK');
    for (let index = 0; index < responseDataOk.length; index = index + 6) {
      deliveryInfoOk.push({
        業者コード: 'OK',
        業者名: transportName,
        問合番号: matchingData[responseDataOk[index]] || responseDataOk[index],
        問合結果: responseDataOk[index + 1],
        問合時間: requestTime,
        結果時間: responseDataOk[index + 2],
        最終配達ターミナル名: responseDataOk[index + 3],
        問合せ電話番号: responseDataOk[index + 4],
        各社サイト: responseDataOk[index + 5],
      });
    }
    const combineDataOk = deliverNumbersOk.map((item) => {
      const deliveryItem = deliveryInfoOk.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'OK', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoOk.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'OK' };
        } else {
          return {
            業者コード: 'OK',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `http://www1.okaken.co.jp/CCB/RMHR0002.PGM?PWD=${mapCustomerCode[item] || ''}&${
              mapCustomerCode[item] ? `SNY=${item}` : `GEN=${item}`
            }`,
          };
        }
      }
    });

    // Process data for 久留米運送
    const deliveryInfoKm = [];
    const responseDataKm = response.data['km'];
    const deliverNumbersKm = response.deliverNumbers['km'];
    transportName = getName('KM');
    for (let index = 0; index < responseDataKm.length; index = index + 6) {
      deliveryInfoKm.push({
        業者コード: 'KM',
        業者名: transportName,
        問合番号: matchingData[responseDataKm[index]] || responseDataKm[index],
        問合結果: responseDataKm[index + 4],
        問合時間: requestTime,
        結果時間: responseDataKm[index + 1],
        最終配達ターミナル名: responseDataKm[index + 2],
        問合せ電話番号: responseDataKm[index + 3],
        各社サイト: responseDataKm[index + 5],
      });
    }
    const combineDataKm = deliverNumbersKm.map((item) => {
      const deliveryItem = deliveryInfoKm.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'KM', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoKm.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'KM' };
        } else {
          return {
            業者コード: 'KM',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: mapCustomerCode[item]
              ? `https://www4.kurumeunsou.co.jp/kurume-trans/cntchk.asp?se_no=${item}&FrmSend=toi_frm&sy_no=${mapCustomerCode[item]}`
              : `https://www4.kurumeunsou.co.jp/kurume-trans/kamotsu.asp?w_no=${item}&FrmSend=toi_frm`,
          };
        }
      }
    });

    // Process data for 松岡満運輸
    const deliveryInfoMm = [];
    const responseDataMm = response.data['mm'];
    const deliverNumbersMm = response.deliverNumbers['mm'];
    transportName = getName('MM');
    for (let index = 0; index < responseDataMm.length; index = index + 6) {
      deliveryInfoMm.push({
        業者コード: 'MM',
        業者名: transportName,
        問合番号: matchingData[responseDataMm[index]] || responseDataMm[index],
        問合結果: responseDataMm[index + 1],
        問合時間: requestTime,
        結果時間: responseDataMm[index + 2],
        最終配達ターミナル名: responseDataMm[index + 3],
        問合せ電話番号: responseDataMm[index + 4],
        各社サイト: responseDataMm[index + 5],
      });
    }
    const combineDataMm = deliverNumbersMm.map((item) => {
      const deliveryItem = deliveryInfoMm.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'MM', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoMm.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'MM' };
        } else {
          return {
            業者コード: 'MM',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://www.matsuokaman.jp/tracking/?mode=4&dno=${item}&qstr=no-1=${item}`,
          };
        }
      }
    });

    // Process data for 四国運輸
    const deliveryInfoSk = [];
    const responseDataSk = response.data['sk'];
    const deliverNumbersSk = response.deliverNumbers['sk'];
    transportName = getName('SK');
    for (let index = 0; index < responseDataSk.length; index = index + 6) {
      deliveryInfoSk.push({
        業者コード: 'SK',
        業者名: transportName,
        問合番号: matchingData[responseDataSk[index]] || responseDataSk[index],
        問合結果: responseDataSk[index + 1],
        問合時間: requestTime,
        結果時間: responseDataSk[index + 2],
        最終配達ターミナル名: responseDataSk[index + 3],
        問合せ電話番号: responseDataSk[index + 4],
        各社サイト: responseDataSk[index + 5],
      });
    }
    const combineDataSk = deliverNumbersSk.map((item) => {
      const deliveryItem = deliveryInfoSk.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'SK', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoSk.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'SK' };
        } else {
          return {
            業者コード: 'SK',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `http://www.gosya.co.jp/tuiseki/tuiseki_search.asp?denno=${item}`,
          };
        }
      }
    });

    // Process data for ラニイ福井貨物
    const deliveryInfoRf = [];
    const responseDataRf = response.data['rf'];
    const deliverNumbersRf = response.deliverNumbers['rf'];
    transportName = getName('RF');
    for (let index = 0; index < responseDataRf.length; index = index + 6) {
      deliveryInfoRf.push({
        業者コード: 'RF',
        業者名: transportName,
        問合番号: matchingData[responseDataRf[index]] || responseDataRf[index],
        問合結果: responseDataRf[index + 1],
        問合時間: requestTime,
        結果時間: responseDataRf[index + 2],
        最終配達ターミナル名: responseDataRf[index + 3],
        問合せ電話番号: responseDataRf[index + 4],
        各社サイト: responseDataRf[index + 5],
      });
    }
    const combineDataRf = deliverNumbersRf.map((item) => {
      const deliveryItem = deliveryInfoRf.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'RF', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoRf.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'RF' };
        } else {
          return {
            業者コード: 'RF',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `http://61.196.145.139/210_trace/detail.asp?no=${item.padStart(16, '0')}`,
          };
        }
      }
    });

    // Process data for 沖縄物流
    const deliveryInfoOw = [];
    const responseDataOw = response.data['ow'];
    const deliverNumbersOw = response.deliverNumbers['ow'];
    transportName = getName('OW');
    for (let index = 0; index < responseDataOw.length; index = index + 6) {
      deliveryInfoOw.push({
        業者コード: 'OW',
        業者名: transportName,
        問合番号: matchingData[responseDataOw[index]] || responseDataOw[index],
        問合結果: responseDataOw[index + 1],
        問合時間: requestTime,
        結果時間: responseDataOw[index + 2],
        最終配達ターミナル名: responseDataOw[index + 3],
        問合せ電話番号: responseDataOw[index + 4],
        各社サイト: responseDataOw[index + 5],
      });
    }
    const combineDataOw = deliverNumbersOw.map((item) => {
      const deliveryItem = deliveryInfoOw.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'OW', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoOw.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'OW' };
        } else {
          return {
            業者コード: 'OW',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `http://gcps.opd.co.jp/kamotsu/tracking.asp?trackingno_1=${item}&trackingno_2=&trackingno_3=&trackingno_4=&trackingno_5=&trackingno_6=&trackingno_7=&trackingno_8=&trackingno_9=&trackingno_10=&trackingno_11=&trackingno_12=&trackingno_13=&trackingno_14=&trackingno_15=`,
          };
        }
      }
    });

    // Process data for ロジネットジャパン西日本
    const combineDataLn = generateDataSattsu(response, matchingData, requestTime, readySearch, 'LN');

    // Process data for 札幌通運
    const combineDataSp = generateDataSattsu(response, matchingData, requestTime, readySearch, 'SP');

    // Process data for 近物レックス
    const deliveryInfoKr = [];
    const responseDataKr = response.data['kr'];
    const deliverNumbersKr = response.deliverNumbers['kr'];
    transportName = getName('KR');
    for (let index = 0; index < responseDataKr.length; index = index + 5) {
      deliveryInfoKr.push({
        業者コード: 'KR',
        業者名: transportName,
        問合番号: matchingData[responseDataKr[index]] || responseDataKr[index],
        問合結果: responseDataKr[index + 1],
        問合時間: requestTime,
        結果時間: responseDataKr[index + 2],
        最終配達ターミナル名: responseDataKr[index + 3],
        問合せ電話番号: responseDataKr[index + 4],
        各社サイト: 'https://www8.kinbutsurex.co.jp/GenpyoShokai/WGNSY010.aspx',
      });
    }
    const combineDataKr = deliverNumbersKr.map((item) => {
      const deliveryItem = deliveryInfoKr.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'KR', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoKr.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'KR' };
        } else {
          return {
            業者コード: 'KR',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: 'https://www8.kinbutsurex.co.jp/GenpyoShokai/WGNSY010.aspx',
          };
        }
      }
    });

    // Process data for 新潟運輸
    const deliveryInfoNg = [];
    const responseDataNg = response.data['ng'];
    const deliverNumbersNg = response.deliverNumbers['ng'];
    transportName = getName('NG');
    for (let index = 0; index < responseDataNg.length; index = index + 5) {
      deliveryInfoNg.push({
        業者コード: 'NG',
        業者名: transportName,
        問合番号: matchingData[responseDataNg[index]] || responseDataNg[index],
        問合結果: responseDataNg[index + 1],
        問合時間: requestTime,
        結果時間: responseDataNg[index + 2],
        最終配達ターミナル名: responseDataNg[index + 3],
        問合せ電話番号: responseDataNg[index + 4],
        各社サイト: 'http://www2.nuis.co.jp/kzz80011.htm',
      });
    }
    const combineDataNg = deliverNumbersNg.map((item) => {
      const deliveryItem = deliveryInfoNg.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'NG', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoNg.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'NG' };
        } else {
          return {
            業者コード: 'NG',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: 'http://www2.nuis.co.jp/kzz80011.htm',
          };
        }
      }
    });

    // Process data for メタル便
    const deliveryInfoMb = [];
    const responseDataMb = response.data['mb'];
    const deliverNumbersMb = response.deliverNumbers['mb'];
    transportName = getName('MB');
    for (let index = 0; index < responseDataMb.length; index = index + 5) {
      deliveryInfoMb.push({
        業者コード: 'MB',
        業者名: transportName,
        問合番号: matchingData[responseDataMb[index]] || responseDataMb[index],
        問合結果: responseDataMb[index + 1],
        問合時間: requestTime,
        結果時間: responseDataMb[index + 2],
        最終配達ターミナル名: responseDataMb[index + 3],
        問合せ電話番号: responseDataMb[index + 4],
        各社サイト: 'https://ts22.jp/Client',
      });
    }
    const combineDataMb = deliverNumbersMb.map((item) => {
      const deliveryItem = deliveryInfoMb.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'MB', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoMb.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'MB' };
        } else {
          return {
            業者コード: 'MB',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: 'https://ts22.jp/Client',
          };
        }
      }
    });

    // Process data for JPロジスティクス
    const combineDataTl = generateDataJapanLogistic(response, matchingData, requestTime, readySearch, 'TL');

    // Process data for 日本通運
    const deliveryInfoNt = [];
    const responseDataNt = response.data['nt'];
    const deliverNumbersNt = response.deliverNumbers['nt'];
    transportName = getName('NT');
    for (let index = 0; index < responseDataNt.length; index = index + 5) {
      deliveryInfoNt.push({
        業者コード: 'NT',
        業者名: transportName,
        問合番号: matchingData[responseDataNt[index]] || responseDataNt[index],
        問合結果: responseDataNt[index + 1],
        問合時間: requestTime,
        結果時間: responseDataNt[index + 2],
        最終配達ターミナル名: responseDataNt[index + 3],
        問合せ電話番号: responseDataNt[index + 4],
        各社サイト: `https://lp-trace.nittsu.co.jp/web/webarpaa702.srv?denpyoNo1=${
          matchingData[responseDataNt[index]] || responseDataNt[index]
        }&denpyoNo6=&denpyoNo2=&denpyoNo7=&denpyoNo3=&denpyoNo8=&denpyoNo4=&denpyoNo9=&denpyoNo5=&denpyoNo10=`,
      });
    }
    const combineDataNt = deliverNumbersNt.map((item) => {
      const deliveryItem = deliveryInfoNt.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'NT', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoNt.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'NT' };
        } else {
          return {
            業者コード: 'NT',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://lp-trace.nittsu.co.jp/web/webarpaa702.srv?denpyoNo1=${item}&denpyoNo6=&denpyoNo2=&denpyoNo7=&denpyoNo3=&denpyoNo8=&denpyoNo4=&denpyoNo9=&denpyoNo5=&denpyoNo10=`,
          };
        }
      }
    });

    // Process data for 中越運送
    const deliveryInfoCt = [];
    const responseDataCt = response.data['ct'];
    const deliverNumbersCt = response.deliverNumbers['ct'];
    transportName = getName('CT');
    for (let index = 0; index < responseDataCt.length; index = index + 5) {
      deliveryInfoCt.push({
        業者コード: 'CT',
        業者名: transportName,
        問合番号: matchingData[responseDataCt[index]] || responseDataCt[index],
        問合結果: responseDataCt[index + 1],
        問合時間: requestTime,
        結果時間: responseDataCt[index + 2],
        最終配達ターミナル名: responseDataCt[index + 3],
        問合せ電話番号: responseDataCt[index + 4],
        各社サイト: `https://www.chuetsu-group.co.jp/bagonize/bagonize_dom.php?bango=${
          matchingData[responseDataCt[index]] || responseDataCt[index]
        }`,
      });
    }
    const combineDataCt = deliverNumbersCt.map((item) => {
      const deliveryItem = deliveryInfoCt.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'CT', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoCt.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'CT' };
        } else {
          return {
            業者コード: 'CT',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://www.chuetsu-group.co.jp/bagonize/bagonize_dom.php?bango=${item}`,
          };
        }
      }
    });

    // Process data for 飛騨運輸
    const deliveryInfoHt = [];
    const responseDataHt = response.data['ht'];
    const deliverNumbersHt = response.deliverNumbers['ht'];
    transportName = getName('HT');
    for (let index = 0; index < responseDataHt.length; index = index + 5) {
      deliveryInfoHt.push({
        業者コード: 'HT',
        業者名: transportName,
        問合番号: matchingData[responseDataHt[index]] || responseDataHt[index],
        問合結果: responseDataHt[index + 1],
        問合時間: requestTime,
        結果時間: responseDataHt[index + 2],
        最終配達ターミナル名: responseDataHt[index + 3],
        問合せ電話番号: responseDataHt[index + 4],
        各社サイト: `http://www.hida-unyu.co.jp/WP_HIDAUNYU_WKSHO_GUEST/KW_UD04013.do?_Action_=a_srcAction`,
      });
    }
    const combineDataHt = deliverNumbersHt.map((item) => {
      const deliveryItem = deliveryInfoHt.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'HT', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoHt.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'HT' };
        } else {
          return {
            業者コード: 'HT',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `http://www.hida-unyu.co.jp/WP_HIDAUNYU_WKSHO_GUEST/KW_UD04013.do?_Action_=a_srcAction`,
          };
        }
      }
    });

    // Process data for 加勢
    const deliveryInfoKs = [];
    const responseDataKs = response.data['ks'];
    const deliverNumbersKs = response.deliverNumbers['ks'];
    transportName = getName('KS');
    for (let index = 0; index < responseDataKs.length; index = index + 5) {
      deliveryInfoKs.push({
        業者コード: 'KS',
        業者名: transportName,
        問合番号: matchingData[responseDataKs[index]] || responseDataKs[index],
        問合結果: responseDataKs[index + 1],
        問合時間: requestTime,
        結果時間: responseDataKs[index + 2],
        最終配達ターミナル名: responseDataKs[index + 3],
        問合せ電話番号: responseDataKs[index + 4],
        各社サイト: `https://www.ks-transport-network.jp/UNY110?tracking_id=${
          matchingData[responseDataKs[index]] || responseDataKs[index]
        }`,
      });
    }
    const combineDataKs = deliverNumbersKs.map((item) => {
      const deliveryItem = deliveryInfoKs.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'KS', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoKs.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'KS' };
        } else {
          return {
            業者コード: 'KS',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://www.ks-transport-network.jp/UNY110?tracking_id=${item}`,
          };
        }
      }
    });

    // Process data for アスクルロジスト
    const deliveryInfoKl = [];
    const responseDataKl = response.data['kl'];
    const deliverNumbersKl = response.deliverNumbers['kl'];
    transportName = getName('KL');
    for (let index = 0; index < responseDataKl.length; index = index + 5) {
      deliveryInfoKl.push({
        業者コード: 'KL',
        業者名: transportName,
        問合番号: matchingData[responseDataKl[index]] || responseDataKl[index],
        問合結果: responseDataKl[index + 1],
        問合時間: requestTime,
        結果時間: responseDataKl[index + 2],
        最終配達ターミナル名: responseDataKl[index + 3],
        問合せ電話番号: responseDataKl[index + 4],
        各社サイト: isKokuyoAccount
          ? `https://www.cargo-logist-concier.jp/delivery/status?no=${
              matchingData[responseDataKl[index]] || responseDataKl[index]
            }&ca=2`
          : `https://cargo.askullogist.co.jp/delivery/status?no=${
              matchingData[responseDataKl[index]] || responseDataKl[index]
            }&ca=0`,
      });
    }
    const combineDataKl = deliverNumbersKl.map((item) => {
      const deliveryItem = deliveryInfoKl.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'KL', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoKl.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'KL' };
        } else {
          return {
            業者コード: 'KL',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: isKokuyoAccount
              ? `https://www.cargo-logist-concier.jp/delivery/status?no=${item}&ca=2`
              : `https://cargo.askullogist.co.jp/delivery/status?no=${item}&ca=0`,
          };
        }
      }
    });

    // Process data for セイノースーパー
    const deliveryInfoSs = [];
    const responseDataSs = response.data['ss'];
    const deliverNumbersSs = response.deliverNumbers['ss'];
    transportName = getName('SS');
    for (let index = 0; index < responseDataSs.length; index = index + 5) {
      deliveryInfoSs.push({
        業者コード: 'SS',
        業者名: transportName,
        問合番号: matchingData[responseDataSs[index]] || responseDataSs[index],
        問合結果: responseDataSs[index + 1],
        問合時間: requestTime,
        結果時間: responseDataSs[index + 2],
        最終配達ターミナル名: responseDataSs[index + 3],
        問合せ電話番号: responseDataSs[index + 4],
        各社サイト: `https://inquire.trc.ssx.seino.co.jp/inquire/Main.jsp?uji.verbs=NAHS0010_find&uji.bean=com.seibu.bean.na.NAHS0010Bean&strDenpyoNo=&strSyukaTenCD=&strNinsyoNo=&strFrontGamen=NAHS0010&strFlg=&bakDenpyoNo1=${
          matchingData[responseDataSs[index]] || responseDataSs[index]
        }&bakDenpyoNo2=&bakDenpyoNo3=&bakDenpyoNo4=&bakDenpyoNo5=&bakDenpyoNo6=&bakDenpyoNo7=&bakDenpyoNo8=&bakDenpyoNo9=&bakDenpyoNo10=&strDenpyoNo1=${
          matchingData[responseDataSs[index]] || responseDataSs[index]
        }&strDenpyoNo2=&strDenpyoNo3=&strDenpyoNo4=&strDenpyoNo5=&strDenpyoNo6=&strDenpyoNo7=&strDenpyoNo8=&strDenpyoNo9=&strDenpyoNo10=`,
      });
    }
    const combineDataSs = deliverNumbersSs.map((item) => {
      const deliveryItem = deliveryInfoSs.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'SS', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoSs.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'SS' };
        } else {
          return {
            業者コード: 'SS',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://inquire.trc.ssx.seino.co.jp/inquire/Main.jsp?uji.verbs=NAHS0010_find&uji.bean=com.seibu.bean.na.NAHS0010Bean&strDenpyoNo=&strSyukaTenCD=&strNinsyoNo=&strFrontGamen=NAHS0010&strFlg=&bakDenpyoNo1=${item}&bakDenpyoNo2=&bakDenpyoNo3=&bakDenpyoNo4=&bakDenpyoNo5=&bakDenpyoNo6=&bakDenpyoNo7=&bakDenpyoNo8=&bakDenpyoNo9=&bakDenpyoNo10=&strDenpyoNo1=${item}&strDenpyoNo2=&strDenpyoNo3=&strDenpyoNo4=&strDenpyoNo5=&strDenpyoNo6=&strDenpyoNo7=&strDenpyoNo8=&strDenpyoNo9=&strDenpyoNo10=`,
          };
        }
      }
    });

    // Process data for ロジクエスト
    const deliveryInfoLq = [];
    const responseDataLq = response.data['lq'];
    const deliverNumbersLq = response.deliverNumbers['lq'];
    transportName = getName('LQ');
    for (let index = 0; index < responseDataLq.length; index = index + 5) {
      deliveryInfoLq.push({
        業者コード: 'LQ',
        業者名: transportName,
        問合番号: matchingData[responseDataLq[index]] || responseDataLq[index],
        問合結果: responseDataLq[index + 1],
        問合時間: requestTime,
        結果時間: responseDataLq[index + 2],
        最終配達ターミナル名: responseDataLq[index + 3],
        問合せ電話番号: responseDataLq[index + 4],
        各社サイト: `https://giq-dx.com/package-tracking?package_1=${
          matchingData[responseDataLq[index]] || responseDataLq[index]
        }`,
      });
    }
    const combineDataLq = deliverNumbersLq.map((item) => {
      const deliveryItem = deliveryInfoLq.filter((x) => x.問合番号 === item.toString());
      if (deliveryItem.length > 0) {
        return { 業者コード: 'LQ', ...deliveryItem[0] };
      } else {
        const deliveryItem = deliveryInfoLq.filter((x) => x.問合番号 === String(readySearch[item]));
        if (deliveryItem.length > 0) {
          return { ...deliveryItem[0], 問合番号: item, 業者コード: 'LQ' };
        } else {
          return {
            業者コード: 'LQ',
            業者名: transportName,
            問合番号: item,
            問合結果: '業者または入力番号エラー',
            問合時間: requestTime,
            結果時間: '',
            最終配達ターミナル名: '',
            問合せ電話番号: '',
            各社サイト: `https://giq-dx.com/package-tracking?package_1=${item}`,
          };
        }
      }
    });

    // Process data for Invalid
    const deliverNumbersInvalid = response.deliverNumbers['invalid'];

    const combineDataInvalid = deliverNumbersInvalid.map((item) => {
      return {
        業者コード: '',
        業者名: '不明',
        問合番号: item,
        問合結果:
          '該当する伝票番号の、追跡結果が見つかりませんでした。伝票番号をご確認いただくかお問い合わせください。',
        問合時間: requestTime,
        結果時間: '',
        最終配達ターミナル名: '',
        問合せ電話番号: '',
        各社サイト: `#`,
      };
    });

    // Process data for Invalid Trader
    const deliverNumbersInvalidTrader = response.deliverNumbers['invalidTrader'];

    const combineDataInvalidTrader = deliverNumbersInvalidTrader.map((item) => {
      const temp = item.split('_');
      return {
        業者コード: temp[1],
        業者名: '不明',
        問合番号: temp[0],
        問合結果: '業者または入力番号エラー',
        問合時間: requestTime,
        結果時間: '',
        最終配達ターミナル名: '',
        問合せ電話番号: '',
        各社サイト: `#`,
      };
    });

    const dataMap = {
      MT: combineData,
      DK: combineDataDk,
      YM: combineDataYm,
      SG: combineDataSg,
      SN: combineDataSn,
      FT: combineDataFt,
      TN: combineDataTn,
      CB: combineDataCb,
      SB: combineDataSb,
      YB: combineDataYb,
      SL: combineDataSl,
      OK: combineDataOk,
      KM: combineDataKm,
      MM: combineDataMm,
      SK: combineDataSk,
      RF: combineDataRf,
      OW: combineDataOw,
      LN: combineDataLn,
      KR: combineDataKr,
      NG: combineDataNg,
      MB: combineDataMb,
      TL: combineDataTl,
      NT: combineDataNt,
      OH: combineDataOh,
      KT: combineDataKt,
      JW: combineDataJw,
      KE: combineDataKe,
      KW: combineDataKw,
      CF: combineDataCf,
      SP: combineDataSp,
      KD: combineDataKd,
      CT: combineDataCt,
      HT: combineDataHt,
      KS: combineDataKs,
      KL: combineDataKl,
      SS: combineDataSs,
      LQ: combineDataLq,
      INVALID: combineDataInvalid,
      INVALID_TRADER: combineDataInvalidTrader,
    };
    const showDataList = [];
    sortTypes.forEach((sortType) => {
      if (dataMap[sortType]) {
        showDataList.push(...dataMap[sortType]);
      }
    });
    const originalPosition = response.data['originalPosition'] || {};
    const sortData = showDataList.sort((a, b) => {
      const firstPosition = `${a['問合番号']}_${a['業者コード']}`;
      const secondPosition = `${b['問合番号']}_${b['業者コード']}`;
      return originalPosition[camelCase(firstPosition)] - originalPosition[camelCase(secondPosition)];
    });
    setCsvData(sortData);

    const originalCsvData = response.data['originalCsvData'] || {};
    setOriginalCsvData(originalCsvData);

    const currentProgress = response.currentProgress || { current: 0, total: 1 };
    setProgressInfo(currentProgress);
  };

  const resetData = () => {
    setResetCurrentPage(true);
    setCsvData([]);
    setStatus('');
    setProgressInfo({ ...progressInfo, current: 0, total: 0 });
  };

  const handleGetData = () => {
    tid.current = uuidv4();
    setLoading(true);
    resetData();
    const requestTime = moment(new Date()).format('MM/DD HH:mm');
    getDeliveryInfo(
      isFree || isDedicatedSearch ? freeIds.trim() : ids.current,
      isFree,
      transport,
      setStatus,
      showData,
      requestTime,
      isDedicatedSearch,
      tid.current,
      trackingNumberColumn,
      transportColumn,
      companyCodeColumn ? companyCodeColumn : -1,
      haveHeader,
    )
      .then((response) => {
        showData(response, requestTime);
      })
      .catch((error) => {
        showError('エラー', error?.response?.data?.error || '管理会社にお問い合わせください。');
      })
      .finally(() => {
        setLoading(false);
        setCanceling(false);
        setResetCurrentPage(true);
      });
  };

  const handleCancelJob = () => {
    setCanceling(true);
    cancelJob(tid.current);
  };

  const handleChange = (value) => {
    setTransport(value);
  };

  const handleChangeFreeIds = (e) => {
    setFreeIds(e.target.value.replace('\n\n', '\n'));
  };

  const handleChangeTrackingNumberColumn = (value) => {
    console.log(value);
    setTrackingNumberColumn(value);
  };

  const handleChangeTransportColumn = (value) => {
    setTransportColumn(value);
  };

  const handleChangeCompanyCodeColumn = (value) => {
    setCompanyCodeColumn(value);
  };

  const handleChangeCsvName = () => {
    const requestTime = moment(new Date()).format('YYYY-MM-DD_HH-mm');
    setCsvName(`荷物追跡_${requestTime}.csv`);
  };
  // const isInputValid = () => {
  //   return !isEmpty(trackingNumberColumn) && !isEmpty(transportColumn);
  // };

  const renderSelectTransport = () => {
    return (
      <Select value={transport} style={{ width: '100%' }} onChange={handleChange}>
        {transportCategories.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    );
  };

  const handleChangeExportCSVColumn = (selectedColumns) => {
    const exportCsvDataWhenSelectOutputColumn = csvData.map((value) => {
      const trackingNumber = `${value['問合番号']}_${value['業者コード']}`;
      const originalRow = originalCsvData[camelCase(trackingNumber)] || [];
      const returnMap = {};
      originalRow.forEach((item, index) => {
        returnMap[`列${index + 1}`] = item;
      });
      selectedColumns.forEach((selectedColumn) => {
        returnMap[selectedColumn] = value[selectedColumn];
      });
      return returnMap;
    });
    setOutputCsvSelectedColumn(exportCsvDataWhenSelectOutputColumn);
  };

  const onChangeHaveHeader = (fileId, e) => {
    setHaveHeader({ ...haveHeader, [fileId]: e.target.value });
  };

  return (
    <Col
      style={{
        height: '100%',
      }}
    >
      {isFree ? (
        <Row gutter={[12, 12]}>
          <Col span={isDedicatedSearch ? 24 : 12}>
            <Input.TextArea
              value={freeIds}
              onChange={handleChangeFreeIds}
              placeholder='【問い合わせ番号入力蘭(５件まで)】１件ずつ改行して入力ください。'
              autoSize
            />
          </Col>
          <Col span={12}>{renderSelectTransport()}</Col>
        </Row>
      ) : (
        <>
          {!isDedicatedSearch && (
            <>
              {isShowInputColumn && (
                <Row gutter={[12, 12]}>
                  <Col
                    span={3}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Text>問合番号列</Text>
                  </Col>
                  <Col span={2}>
                    <NumericInput
                      value={trackingNumberColumn}
                      onChange={handleChangeTrackingNumberColumn}
                      style={{ width: 50 }}
                    />
                  </Col>
                  <Col
                    span={3}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Text>運送会社列</Text>
                  </Col>
                  <Col span={2}>
                    <NumericInput
                      value={transportColumn}
                      onChange={handleChangeTransportColumn}
                      style={{ width: 50 }}
                    />
                  </Col>
                  <Col
                    span={3}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Text>顧客コード列</Text>
                  </Col>
                  <Col span={2}>
                    <NumericInput
                      value={companyCodeColumn}
                      onChange={handleChangeCompanyCodeColumn}
                      style={{ width: 50 }}
                    />
                  </Col>
                </Row>
              )}

              <Dragger
                {...props}
                style={{
                  marginBottom: '2em',
                  marginTop: '1em',
                }}
                itemRender={(originNode, file, currFileList, actions) => {
                  console.log(file, currFileList, haveHeader);
                  return (
                    <Row gutter={[12, 12]} style={{ width: '50%' }}>
                      <Col flex='20px'>
                        <PaperClipOutlined />
                      </Col>
                      <Col flex='auto'>
                        <Text>{file.name}</Text>
                      </Col>
                      {isShowInputColumn && (
                        <>
                          <Col>
                            <Text>1行目にヘッダー行</Text>
                          </Col>
                          <Col>
                            <Radio.Group
                              value={haveHeader[fileIds.current[file.uid]]}
                              onChange={(e) => onChangeHaveHeader(fileIds.current[file.uid], e)}
                            >
                              <Radio value={true}>ある</Radio>
                              <Radio value={false}>なし</Radio>
                            </Radio.Group>
                          </Col>
                        </>
                      )}

                      <Col flex='100px' style={{ textAlign: 'right' }}>
                        <DeleteOutlined onClick={() => actions.remove(file.uid)} />
                      </Col>
                    </Row>
                  );
                }}
              >
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>
                  この領域にクリックしてファイルを選択するか、又はファイルをドラッグしてアップロードします。
                  <br />
                  ※ファイルは6件以上アップロードできません。
                  <br />
                  ※対応ファイル形式：csv、csv-UTF8、xlsx、xls。
                  <br />
                  {!isShowInputColumn && '※一度の検索件数は1000件までです。'}
                </p>
              </Dragger>
            </>
          )}
          {isDedicatedSearch && (
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Input.TextArea
                  value={freeIds}
                  onChange={handleChangeFreeIds}
                  placeholder='【問い合わせ番号入力蘭(５件まで)】１件ずつ改行して入力ください。'
                  autoSize
                />
              </Col>
            </Row>
          )}
        </>
      )}

      {(trackingNumber.length > 0 || freeIds.trim()) && (
        <Button
          type='primary'
          size='large'
          onClick={loading ? handleCancelJob : handleGetData}
          style={{
            marginRight: '20px',
            marginTop: '2em',
            backgroundColor: isDedicatedSearch ? '#0045a3' : undefined,
          }}
          loading={isCanceling}
        >
          {loading ? 'キャンセル' : '情報取得'}
        </Button>
      )}
      {csvData.length > 0 && (
        <CSVLink
          className={`ant-btn ant-btn-primary ant-btn-lg ${isDedicatedSearch ? 'csv-button-osaka' : ''}`}
          data={csvData}
          filename={csvName}
          target='_blank'
          style={{
            marginRight: '20px',
          }}
          onClick={handleChangeCsvName}
        >
          CSV出力
        </CSVLink>
      )}
      {csvData.length > 0 && isShowExportCSVWithSpecifyColumn && (
        <SelectColumnExportModal csvData={outputCsvSelectedColumn} handleChange={handleChangeExportCSVColumn} />
      )}
      {csvData.length > 0 && isShowInputColumn && (
        <Button
          type='primary'
          size='large'
          onClick={() => resetData()}
          style={{
            marginLeft: '20px',
            marginTop: '2em',
          }}
        >
          クリア
        </Button>
      )}
      <br />
      <Row gutter={[12, 12]} style={{ marginTop: '1em', marginBottom: '1em' }}>
        <Col span={18}>
          <Text
            style={{
              color: 'blue',
              fontSize: '1rem',
              fontWeight: '700',
            }}
          >
            {status}
          </Text>
        </Col>
        {isShowInputColumn && !!status && (
          <Col span={6} style={{ alignContent: 'flex-end' }}>
            <Text
              style={{
                color: 'blue',
                fontSize: '0.75rem',
                fontWeight: '700',
              }}
            >
              {`${numberWithCommas(progressInfo.current)}件 / ${numberWithCommas(progressInfo.total)}件`}
            </Text>
            <Progress
              percent={Math.round((progressInfo.current * 100) / progressInfo.total)}
              status={loading ? 'active' : progressInfo.current !== progressInfo.total ? 'exception' : 'success'}
              trailColor='#FFFFFF'
              strokeWidth={12}
            />
          </Col>
        )}
      </Row>

      {isMobile && (isDedicatedSearch || isFree) ? (
        <DeliverInfoListMobile csvData={csvData} />
      ) : (
        <DeliverInfoList csvData={csvData} reset={resetCurrentPage} />
      )}
      {/* {isDedicatedSearch && (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <Button
            type='primary'
            size='large'
            disabled={loading}
            onClick={() => history.push('/upload-linked-data')}
          >
            大阪商運管理
          </Button>
        </div>
      )} */}
    </Col>
  );
}
